angular.module('MyHippoProducer.Controllers').directive('formField',  function () {
    return {
        restrict: 'A',
        scope: {
            path: '@',
            label: '@',
            readOnlyValue: '<',
            isDisabled: '<',
            tooltip: '<',
            formatOverride: '@',
        },
        templateUrl: 'components/form/field/field.html',
        controller: function ($scope, PolicyService, WorkflowAdapter, $stateParams, $sce) {
            if ($scope.path) {
                $scope.field = WorkflowAdapter.getField($scope.path);
                if ($scope.field) {
                    // Detect dropdown type
                    const { format, values } = $scope.field;
                    if (!$scope.formatOverride && values && !$scope.readOnlyValue) {
                        $scope.formatOverride = `dropdown:${format || 'string'}`;
                    }

                    // Add label from field if label doesn't exist
                    if (!$scope.label && $scope.field.label) {
                        const label = WorkflowAdapter.evaluateExpression($scope.field.label);
                        $scope.label = $sce.trustAsHtml(label.replace('\n', '</br>'));
                    }

                    // Add an action if there is a valid action
                    if ($scope.field.action) {
                        $scope.fieldAction = WorkflowAdapter.createActionFn($scope.field.action);
                    }

                    // Check if dependant section exists
                    const { pageKey, key } = $scope.field;
                    $scope.field.relatedSection = WorkflowAdapter.findRelatedSection(pageKey, key);

                    // Is Editable
                    $scope.disableEdit = $stateParams.mode !== 'edit' || $scope.field.isReadOnly;

                    // Detect min-max-input type
                    const {step, max, min} = $scope.field;
                    if (step && !max && !min) {
                        $scope.formatOverride = 'minMax';
                    }

                    $scope.tooltip =   WorkflowAdapter.evaluateExpression($scope.field.info);
                }
            }
        }
    };
});
